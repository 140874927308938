.innerRowDiv {
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.innerRowText {
  text-align: left;
  margin: 5px 0px;
}

.tableDiv {
  padding: 10px;
}
.creditCardRow {
  justify-content: space-between;
}
