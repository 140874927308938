body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mychat {
  text-align: left;
  padding: 5px;
}

.theirchat {
  text-align: right;
  padding: 5px;
}

.noMessages {
  margin: 40px;
}

.scrollable {
  margin: 5px;
  padding: 5px;
  width: 97%;
  height: 600px;
  overflow: auto;
  text-align: justify;
  display: flex;
  flex-direction: column-reverse;
  outline: auto;
  outline-color: #e0e0e0;
  font-weight: normal;
}

.line {
  height: 1px;
  background-color: #ededed;
  border: none;
}

#txtChat {
  /* font-size: 18px;
  overflow-wrap: break-word;
  width: 75%; 

  display: inline-block;
  margin-top: 5px; */
  width: 80%;
  font-size: larger;
  margin-left: 5px;
}

#submit {
  /* height: 50px;
  display: flexbox; 
  top: -20px;
  display: inline-block; */
  width: 20%;
  margin-right: 5px;
}

.bottom {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
}

.circ {
  border-radius: 50%;
  height: 25px;
}

.chatTable {
  width: 100%;
}

.pfp {
  width: 8%;
  vertical-align: top;
  padding-top: 7px;
}

.middle {
  width: auto;
}

.date {
  width: 32%;
  text-align: right;
  vertical-align: top;
}
.heart {
  margin-right: 5px;
  color: red;
}
.heart:hover {
  color: rgba(255, 0, 0, 0.6);
  cursor: pointer;
}
